// Resets

// Layout
td {
  vertical-align: middle;
  white-space: nowrap;
}
th {
  vertical-align: middle;
  white-space: nowrap;
}
.table-hover > tbody > tr:hover {
    background-color: #F5F5FD;
}
// Typography
p {
  font-size: 12px;
}
