body{
    &.login{
        background: orange; /* For browsers that do not support gradients */
        background: -webkit-linear-gradient(left right, orange 50%, yellow 100%); /* For Safari 5.1 to 6.0 */
        background: -o-linear-gradient(bottom right, orange 50%, yellow 100%); /* For Opera 11.1 to 12.0 */
        background: -moz-linear-gradient(bottom right, orange 50%, yellow 100%); /* For Firefox 3.6 to 15 */
        background: linear-gradient(to bottom right, orange 50%, yellow 100%); /* Standard syntax */
        height: 100vh;
    }
}

.navbar-row {
    margin-left: -15px;
    margin-right: -15px;
}

.login-panel {
    height: 90vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    > h1{
        color: #fff;
        margin-bottom: 30px;
    }

    > .login{
        @extend .card;

        > .login-content{
            padding: 30px 50px;
        }
    }
}
.footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    /* Set the fixed height of the footer here */
    height: 60px;
    line-height: 60px; /* Vertically center the text there */
    background-color: #f5f5f5;
}

.distinct-retained > td {
    border-top: 0 solid #FFF !important;
    background: #f3f3f3;
}

.mb-60 {
    margin-bottom: 60px;
}


.no-js #loader { display: none;  }
.js #loader { display: block; position: absolute; left: 100px; top: 0; }
.se-pre-con {

    left: 0;
    top: 0;
    width: 100%;
    height: 90vh;
    z-index: 9999;
    background: url('/plugins/pixelpixel/tasktracker/assets/img/preloader.gif') center no-repeat #fff;
}

.btn-icon {
    width: 34px;
}

.progress {
    display: flex;
    height: 1.5rem;
    font-size: .75rem;
    background-color: #cf2434;
}
