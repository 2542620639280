.notification-container {
  .dropdown-menu {
    min-width: 500px;
    padding: .5em;
    border: 5px solid #000;
    li {
      &:nth-child(1) {
        font-size: 1.2em;
        font-weight: bold;
      }
      &.divider {
        height: 2px;
        background-color: #00c176;
      }
    }
  }
}
.image-notif {
  max-height: 32px;
  display: inline;
  margin-top: -80px;
  margin-right: 25px;
  border-radius: 320px;
}

.people-container {

}

.person-container {
  display: inline;
  float: right;
}

.status-row {
  margin-top: -20px;
}

.status-icons {
  div {
    display: inline-block;
  }
}

.notification-holder {
  padding: 5px 5px 5px 5px;
}

.img-notif {
  margin-top: 5px;
}

.date-notif {
  margin-right: 20px;
}

.status-icons {
  display: inline-block;
  margin-top: -20px;
}

.status-date {
  display: inline-block;
  float: right;
}

.img-notif:hover {
  
}