.center-text {
  text-align: center;
}
.right-text {
  text-align: right;
}
.left-text {
  text-align: left;
}
.centralise-mob {
  @include media_max($sm) {
    text-align: center;
    float: none !important;
  }
}
.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.slight_fade {
  opacity: 0.8;
}
.block {
  display: block;
}
.inline {
  display: inline;
}
.inline-block {
  display: inline-block;
}
.orange {
  color: $pxpx-orange;
}
.green {
  color: $pxpx-green;
}
.red {
  color: $pxpx-red;
}
.white {
  color: $white;
}
