
// Display
@mixin flexbox($direction, $justify, $align) {
  display: flex;
  flex-direction: $direction;
  justify-content: $justify;
  align-items: $align;
  flex-wrap: wrap;
}

// Media
@mixin media_max($width) {
  @media (max-width: $width+px) {
    @content;
  }
}
@mixin media_min($width) {
  @media (min-width: $width+px) {
    @content;
  }
}
@mixin media_min_max($min, $max) {
  @media (min-width: $min+px) and (max-width: $max+px) {
    @content;
  }
}

// Typography
@mixin text($size, $color, $family) {
  font-size: $size;
  font-family: $family;
  color: $color;
}

// Status blocks
@mixin statusBlock($color1, $color2) {
  background-color: $color1 !important;
  border-bottom-color: $color2 !important;
}

@mixin colorOnly($color1) {
  color: $color1 !important;
}

// General
