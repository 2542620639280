// Grid
$xs: 480;
$sm: 767;
$md: 992;
$lg: 1200;
$xl: 1600;
$xxl: 2500;

$grid-columns: 24 !default;

// Colours
$black: #000;
$white: #fff;
$light-grey: #c4c4c4;
$mid-grey: #B0B0B0;
$dark-grey: #333333;
$blue-grey: #F5F5FD;

$red: #e2445c;
$red_lowlight: #CE3048;
$light-green: #00c875;
$light-green_lowlight: #00B461;
$dark-green: #037f4c;
$dark-green_lowlight: #006B38;
$light-blue: #0086c0;
$light-blue_lowlight: #3DB0DF;
$dark-blue: #579bfc;
$lilac: #a25ddc;
$lilac_lowlight: #9238AF;
$light-yellow: #CAB641;
$light-yellow_lowlight: #C0AB31;
$dark-yellow: #FFCB00;
$dark-yellow_lowlight: #C0AB1B;
$orange: #fdab3d;
$orange_lowlight: #E99729;

$pxpx-orange: #f07f16;
$pxpx-green: #00c176;
$pxpx-red: #ff003c;

$color_3: rgb(253, 171, 61);
$color_1: rgb(0, 200, 117);
$color_2: rgb(226, 68, 92);
$color_7: rgb(0, 134, 192);
$color_4: rgb(162, 93, 220);
$color_5: rgb(196, 196, 196);
$color_6: rgb(3, 127, 76);
$color_8: rgb(87, 155, 252);
$color_9: rgb(202, 182, 65);
$color_13: rgb(255, 203, 0);
$color_10: rgb(51, 51, 51);
$color_11: rgb(187, 51, 84);
$color_12: rgb(255, 21, 138);
$color_17: rgb(255, 90, 196);
$color_14: rgb(120, 75, 209);
$color_15: rgb(156, 211, 38);
$color_16: rgb(102, 204, 255);
$color_20: rgb(128, 128, 128);
$color_18: rgb(127, 83, 71);
$color_19: rgb(255, 100, 46);

.test {
    color: #d27902;
}

$color_1_low: darken($color_1, 20%);
$color_2_low: darken($color_2, 20%);
$color_3_low: darken($color_3, 20%);
$color_4_low: darken($color_4, 20%);
$color_5_low: darken($color_5, 20%);
$color_6_low: darken($color_6, 20%);
$color_7_low: darken($color_7, 20%);
$color_8_low: darken($color_8, 20%);
$color_9_low: darken($color_9, 20%);
$color_10_low: darken($color_10, 20%);
$color_11_low: darken($color_11, 20%);
$color_12_low: darken($color_12, 20%);
$color_13_low: darken($color_13, 20%);
$color_14_low: darken($color_14, 20%);
$color_15_low: darken($color_15, 20%);
$color_16_low: darken($color_16, 20%);
$color_17_low: darken($color_17, 20%);
$color_18_low: darken($color_18, 20%);
$color_19_low: darken($color_19, 20%);
$color_20_low: darken($color_20, 20%);


// Color lists for status blocks
$blockColourList: $color_1 $color_2 $color_3 $color_4 $color_5 $color_6 $color_7 $color_8 $color_9 $color_10 $color_11 $color_12 $color_13 $color_14 $color_15 $color_16 $color_17 $color_18 $color_19 $color_20;
$blockBottomList: $color_1_low $color_2_low $color_3_low $color_4_low $color_5_low $color_6_low $color_7_low $color_8_low $color_9_low $color_10_low $color_11_low $color_12_low $color_13_low $color_14_low $color_15_low $color_16_low $color_17_low $color_18_low $color_19_low $color_20_low;

$filtersColor: rgba(0, 193, 118, 0.08);

// Color maps for clients:
