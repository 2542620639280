
.fade-enter-active, .fade-leave-active {
    opacity: 1;
}
.fade-enter, .fade-leave-active {
    opacity: 0;
    transition: opacity .5s;
}



.fade-red-enter-active, .fade-red-leave-active {
    opacity: 1;
}
.fade-red-enter, .fade-red-leave-active {
    opacity: 0;
    transition: opacity .5s;
}
li.filter-sgroup {
    padding: 5px;
    border-radius: 15px;
}