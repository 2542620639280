.navbar-dark {
  background-color: #333;
  margin-bottom: 0;
  border-radius: 0;
  border: 0;
  .navbar-nav > li > a:hover, .navbar-default .navbar-nav > li > a:focus {
    color: #FFF;
    background-color: transparent;
  }
}
