// Client colours
$pxpx-orange: #f07f16;
$pxpx-green: #00c176;
$pxpx-red: #ff003c;

$tasktracker-blue: #007DA6;

$b-smith: #f47920;
$rwk-purple: #520021;
$rwk-red: #b31f17;
$hja: #452242;
$humres: #991923;
$fcms: #ffd800;
$le-futures: #f26802;
$li-futures: #243359;
$mtn-view-mx: #ffba00;
$gpsl: #1E1148;
$sparkol: #cc427c;
$videoscribe: #27a8cb;
$frankly: #89d300;
$carriage: #e04538;
$whf: #1babe3;
$optima: #00365a;
$pro-detail: #28235d;
$this-is-stone: #007DA6;
$dvg-stone: #999999;
$b-letter-comms: #000;
$clinical-neg: #035891;
$dental-neg: #1b7b7b;
$render: #7fb93a;

$sportsinb: $mid-grey;
$moors: $mid-grey;
$vantari: $mid-grey;
$sys: $mid-grey;


// Colouring rows according to client

.client{
    border-left: 10px solid transparent;
}

.client-Pixel.Pixel {
  border-left: 10px solid $pxpx-orange;
  td:nth-child(2) a {
    color: $pxpx-orange;
  }
}
.client-Bradley.Smith.Racing.Ltd{
  border-left: 10px solid $b-smith;
  td:nth-child(2) a {
    color: $b-smith;
  }
}
.client-Royds.Withy.King {
  border-left: 10px solid $rwk-red;
  td:nth-child(2) a {
    color: $rwk-red;
  }
}
.client-Hodge.Jones.\&.Allen {
  border-left: 10px solid $hja;
  td:nth-child(2) a {
    color: $hja;
  }
}
.client-Humres.Recruitment {
  border-left: 10px solid $humres;
  td:nth-child(2) a {
    color: $humres;
  }
}
.client-Financial.Claims.Made.Simple {
  border-left: 10px solid $fcms;
  td:nth-child(2) a {
    color: $fcms;
  }
}
.client-Legal.Futures {
  border-left: 10px solid $le-futures;
  td:nth-child(2) a {
    color: $le-futures;
  }
}
.client-Litigation.Futures {
  border-left: 10px solid $li-futures;
  td:nth-child(2) a {
    color: $li-futures;
  }
}
.client-Mountain.View.MX.Park {
  border-left: 10px solid $mtn-view-mx;
  td:nth-child(2) a {
    color: $mtn-view-mx;
  }
}
.client-Global.Publishing.Solutions.Ltd {
  border-left: 10px solid $gpsl;
  td:nth-child(2) a {
    color: $gpsl;
  }
}
.client-Sparkol.Ltd {
  border-left: 10px solid $sparkol;
  td:nth-child(2) a {
    color: $sparkol;
  }
}
.client-Videoscribe {
  border-left: 10px solid $videoscribe;
  td:nth-child(2) a {
    color: $videoscribe;
  }
}
.client-Frankly.Recruitment.Ltd {
  border-left: 10px solid $frankly;
  td:nth-child(2) a {
    color: $frankly;
  }
}
.client-The.Carriage.Foundation {
  border-left: 10px solid $carriage;
  td:nth-child(2) a {
    color: $carriage;
  }
}
.client-Louise {
  border-left: 10px solid $whf;
  td:nth-child(2) a {
    color: $whf;
  }
}
.client-Optima.Group.Ltd {
  border-left: 10px solid $optima;
  td:nth-child(2) a {
    color: $optima;
  }
}
.client-Jordan {
  border-left: 10px solid $pro-detail;
  td:nth-child(2) a {
    color: $pro-detail;
  }
}
.client-Max {
  border-left: 10px solid $this-is-stone;
  td:nth-child(2) a {
    color: $this-is-stone;
  }
}
.client-Max {
  border-left: 10px solid $dvg-stone;
  td:nth-child(2) a {
    color: $dvg-stone;
  }
}
.client-Black.Letter.Comms {
  border-left: 10px solid $b-letter-comms;
  td:nth-child(2) a {
    color: $b-letter-comms;
  }
}
.client-Clinical.Negligence {
  border-left: 10px solid $clinical-neg;
  td:nth-child(2) a {
    color: $clinical-neg;
  }
}
.client-Dental.Negligence {
  border-left: 10px solid $dental-neg;
  td:nth-child(2) a {
    color: $dental-neg;
  }
}
.client-Daryl {
  border-left: 10px solid $vantari;
  td:nth-child(2) a {
    color: $vantari;
  }
}
.client-Start.Your.Systems {
  border-left: 10px solid $sys;
  td:nth-child(2) a {
    color: $sys;
  }
}
.client-Rendermedia.Ltd {
  border-left: 10px solid $render;
  td:nth-child(2) a {
    color: $render;
  }
}
.client-Stuart {
  border-left: 10px solid $sportsinb;
  td:nth-child(2) a {
    color: $sportsinb;
  }
}
.client-Moors.Construction.Ltd {
  border-left: 10px solid $moors;
  td:nth-child(2) a {
    color: $moors;
  }
}
