// Heading row
.head-row {
  background: $dark-grey;
  border-bottom: 1px solid $mid-grey;
  padding: .25em;
    @include media_max($sm) {
      padding: 1em;
    }
  h1 {
    @include text(3em, $white, inherit);
    margin: .25em;
    display: inline;
  }
  .fa-refresh {
    @include text(1.5em, $pxpx-green, FontAwesome);
    vertical-align: super;
    &:hover {
      animation: pulse 1s .25s ease-in-out 1 forwards;
      cursor: pointer;
    }
  }
  .btn-show {
    color: $white;
    opacity: 1;
    padding: 1em;
    margin: .5em;
    float: right;
    font-size: 1em;
    border: 1px solid $white;
      @include media_max($sm) {
        float: none;
      }
      &:hover {
        background: $white;
        color: $black;
        transition: all 1s;
      }
  }
}

.panel-refresh, .panel-buttons{
  padding-top: 30px;
}

.panel-refresh{
  padding-top: 34px;
}

// Filters
.filter-row {
  padding: .25em .25em;
    @include media_max($sm) {
      margin-bottom: 1em;
    }
  .filterbox {
      padding: 5px;
    @include media_max($sm) {
      margin: 1em auto;
    }
    select {
      height: 2em;
      margin: 0 .5em 0 0;
    }
    .filter-title {
      @include text(12px, $white, inherit);
      margin: 0;
      padding: .2em;
    }

    .filter-sgroup{
      .group{
        background: #f5f5f5;
        padding: 4px;
      }
    }
  }
  .btn-group, .btn-group.open {
      width: 100%;
      button {
          width: 100%;
          background-color: $pxpx-green;
          color: $white;
          border: none;
          &:hover, &:active, &:focus {
              background-color: $tasktracker-blue;
              color: $white;
          }
      }
  }
  // Filtering dropdowns
  .dropdown-menu {
      min-width: 500px;
      padding: .5em;
      border: 5px solid $black;
      li:nth-child(n+3) {
        float: left;
        width: 48%;
        margin: 1%;
      }
      li:nth-child(1) {
          font-size: 1.2em;
          font-weight: bold;
      }
      li.divider {
          height: 2px;
          background-color: $pxpx-green;
      }
      label {
          width: 100%;
      }
  }
  @include media_max($sm) {
    .dropdown-menu {
      min-width: 100%;
      max-height: 50vh;
      overflow: scroll;
      li:nth-child(n+3) {
        float: none;
        width: 100%;
      }
    }
  }
  // Datepickers
  .datepicking {
      input {
        width: 100%;
        text-align: center;
        // background: $pxpx-green;
        background: $tasktracker-blue;
        border: none;
        color: $white;
        padding: 6px 30px 6px 30px;
        border-radius: 4px;
        &:hover, &:active, &:focus, &[set="true"] {
            background: $pxpx-green;
            cursor: pointer;
        }
        &[set="false"] {
            background: $tasktracker-blue;
        }
        @include media_max($md) {
            padding-left: 10px;
        }
      }
      span.fa {
        position: absolute;
        color: $white;
        &:hover, &:active, &:focus {
            cursor: pointer;
        }
        &-calendar {
            top: 37px;
            left: 10px;
            @include media_max($md) {
                display: none;
            }
        }
        &-times {
            top: 37px;
            right: 10px;
        }
      }
  }
}

// Retainer area
.retainer-row {
  border-bottom: 1px solid $black;
  background: $blue-grey;
  padding: 1em 2em 3em 2em;

  .btn {
    margin-bottom: 1em;
  }
}

// Pagination
.page-navigation {
    text-align: center;
    .pagination {
    > li {
        > a {
            background-color: $pxpx-green;
            border: 1px solid $white;
            color: $white;
            &:hover {
                background-color: #B0B0B0;
            }
        }
        a[aria-label="Previous"], a[aria-label="Next"] {
            background-color: $tasktracker-blue;
            &:hover {
                background-color: #B0B0B0;
            }
        }
        &.pg-dots a {
            background-color: $white;
            color: $black;
            padding: 6px 6px;
            &:hover {
                cursor: default;
            }
        }
        &.pg-current-page a {
            background-color: $tasktracker-blue;
            padding: 12px 14px;
            &:hover {
                background-color: #B0B0B0;
            }
        }
    }
    @include media_max($sm) {
        li {
            &.pg-dots, &.pg-num {
                a {
                    display: none;
                }
            }
            &.pg-current-page a {
                background-color: $pxpx-green;
            }
        }
    }
    }
}
