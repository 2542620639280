.flip-list-move {
  transition: transform .5s;

}
@keyframes spin {
  0% {}
  100% {
    transform: rotate(180deg);
  }
}
@keyframes pulse {
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

.spin {
  animation: spin 1s ease-in-out infinite forwards;
}
//.pulse {
//  animation: pulse 1s ease 1 forwards;
//}
